import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Hero from "../hero/Hero";
import RecentWork from "../recent-work/RecentWork";
import Team from "../team/Team";
import CoreService from "../core-service/CoreService";
import HomeAbout from "../home-about/HomeAbout";
import Quality from "../quality/Quality";
import Location from "../location/Location";

const Home = () => {
  return (
    <div>
      <Header />
      <Hero />
      <CoreService />
      <HomeAbout />
      {/* <Quality /> */}
      {/* <RecentWork bg={"#000"} color={"#fff"} /> */}
      {/* <Team /> */}
      <section className="py-6 bg-white sm:py-8 lg:py-12 mt-[-60px]">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="px-8 py-10 overflow-hidden lg:px-24 md:py-10 lg:py-8 bg-gray-50 rounded-3xl">
            <p className="font-normal text-[18px] text-black">
              We have a team of qualified engineers, skilled technicians, and
              electrical helpers, each with over 10 years of experience,
              enabling us to effectively execute various projects with bespoke
              needs. Our electricians are highly experienced and accredited,
              trained to the IEE 18th Edition (BS7671 Wiring Regulations)
              standards and other relevant programs, Additionally, our
              construction operatives are certified and possess CSCS cards. We
              have resources available across the UK, hired or contracted as
              needed for specific projects. We also maintain strong networks
              with outsourcing companies that supply experienced, competent, and
              certified technicians, electrical helpers, and construction
              operatives.
            </p>
          </div>
        </div>
      </section>
      <Location />
      <Footer />
    </div>
  );
};

export default Home;
